<template>
  <div
    class="imageCropper_container global-center"
    v-loading="isloading"
    element-loading-text="图片上传中"
  >
    <div class="imageCropper_main">
      <div class="imageCropper_button_group">
        <customButton class="imageCropper_button" @click="doScale(1)"
          >放大</customButton
        >
        <customButton class="imageCropper_button" @click="doScale(0)"
          >缩小</customButton
        >
        <customButton class="imageCropper_button" @click="doRotate"
          >旋转</customButton
        >
        <customButton class="imageCropper_button" @click="doCut"
          >确认</customButton
        >
      </div>
      <div class="imageCropper_area">
        <div
          class="imageCropper_headimg_calibration_container"
          v-if="isShowHeadimgCalibration"
          @touchstart="touchstart"
          @mousedown="touchstart"
        >
          <div
            class="imageCropper_headimg_calibration"
            :style="{ height: CropHeight + 'px', width: CropWidth + 'px' }"
          >
            <img
              draggable="false"
              :src="require('@/assets/img/resume_img_have.png')"
              alt=""
            />
          </div>
        </div>
        <VueCropper
          :style="{ height: CropHeight + 'px', width: CropWidth + 'px' }"
          ref="cropper"
          :img="data.url"
          :autoCrop="true"
          :fixedBox="true"
          :autoCropWidth="CropWidth"
          :autoCropHeight="CropHeight"
          :canMoveBox="false"
          :canMove="true"
          :full="true"
          :canScale="true"
          :fixedNumber="flexScale"
          :outputType="outputType"
          :outputSize="0.5"
          mode="100% 100%"
        ></VueCropper>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-cropper/dist/index.css";
import { VueCropper } from "vue-cropper";
import customButton from "@/components/unit/CustomButton";
import uploads from "@/common/uploads";
export default {
  name: "imageCropper",
  components: {
    VueCropper: VueCropper,
    customButton: customButton,
  },
  props: {
    MaxSize: {
      type: Number,
      default: 0,
    },
    CropWidth: {
      type: Number,
      default: 300,
    },
    CropHeight: {
      type: Number,
      default: 150,
    },
    flexScale: {
      type: Array,
      default: [2, 1],
    },
    outputType: {
      type: String,
      default: "jpeg",
    },
    uploadUrl: {
      type: String,
      default: "",
    },
    uploadItem: {
      type: Object,
      default: {
        upload_type: "",
      },
    },
    isShowHeadimgCalibration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isloading: false,
      data: {
        url: "",
      },
    };
  },
  methods: {
    moveImg() {
      // this.$refs.cropper
    },
    doRotate() {
      console.log("旋转");
      this.$refs.cropper.rotateRight();
    },
    doScale(status) {
      console.log("缩小放大");
      let scale = status === 0 ? -1 : 1;
      this.$refs.cropper.changeScale(scale);
    },
    doCut() {
      console.log("剪裁");
      this.$refs.cropper.getCropData(async (file) => {
        // console.log("MaxSize=====",this.MaxSize,this.base64ImageSize(file));
        // console.log(file)
        let imgSize = this.base64ImageSize(file);
        if (this.MaxSize != 0 && imgSize > this.MaxSize) {
          this.$tips.error({
            text: `图片最大为${this.MaxSize}MB，请选择更小的图片`,
          });
          this.$emit("receiveData", { url: "" });
          return;
        }
        this.isloading = true;
        let item = {
          ...this.uploadItem,
          type: "cut",
        };
        let url = await uploads.uploadBase64({
          file,
          item,
        });
        this.isloading = false;
        this.data.url = url;
        this.$emit("receiveData", this.data);
      });
    },
    //计算base64图片大小
    base64ImageSize(base64ImageStr) {
      const indexBase64 = base64ImageStr.indexOf("base64,");
      if (indexBase64 < 0) return -1;
      const str = base64ImageStr.substr(indexBase64 + 6);
      return ((str.length * 0.75) / 1024 / 1024).toFixed(2);
    },
    async getBase64(url) {
      return new Promise((resolve) => {
        console.log("getBase64==============", url);
        let image = new Image();
        image.src = url;
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          let dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
        };
        image.onerror = function (err) {
          console.log(JSON.stringify(err));
        };
      });
    },
    async setData(url) {
      try {
        this.data.url = await this.getBase64(url);
        // console.log(this.data.url)
      } catch (err) {
        console.log(JSON.stringify(err));
      }
    },
    loadUrl(url) {
      this.data.url = url;
    },
    touchstart(e) {
      console.log("11111 :>> ", 11111);
      this.$refs.cropper.startMove(e);
    },
  },
};
</script>
<style>
* {
  touch-action: pan-y;
}
.imageCropper_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  /* background:red; */
  background: rgba(30, 30, 30, 0.7);
}
.imageCropper_main {
  width: 80%;
}
.imageCropper_area {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.imageCropper_button_group {
  display: flex;
  justify-content: space-between;
}
.imageCropper_button {
  margin-right: 10px;
  width: 60px !important;
  min-width: 60px !important;
}
.imageCropper_button:nth-child(4) {
  margin-right: 0;
}
.imageCropper_headimg_calibration_container {
  width: 100%;
  height: 100%;
  background: rgba(129, 129, 129, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50001;
  display: flex;
  justify-content: center;
}
.imageCropper_headimg_calibration {
  border-radius: 40%;
}
.imageCropper_headimg_calibration img {
  height: 100%;
  width: 100%;
  /* border:3px solid #fff; */
}
</style>